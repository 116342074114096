:root {
  --primary: #4c2563;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mobile-hamburger {
  position: absolute;
  left: 10px;
  top: 17px;
}

i.create {
  font-size: 16pt;
  color: var(--primary);
}

.fb-50 {
  flex-basis: 50%;
}

.min-height-100vh {
  min-height: 100vh;
}

.bg-e5 {
  background: #e5e5e5;
}

.gap-l {
  gap: 3rem;
}

#registrationCompleted {
  border: 7px solid #4c2563;
  border-radius: 5px;
  height: 500px;
  margin-top: 4rem;
}

#registrationCompleted a {
  width: 250px;
  font-size: 1.1rem;
  font-weight:bold;
  color: white;
}

#registrationCompleted p {
  font-size: 1.2rem;
  color: #6c757d;
}